<template>
  <div data-test="video-card">
    <VRow v-if="showDescription">
      <VCol
        data-test="video-item-name"
        class="tt-text-title-2"
      >
        {{ meta.name }}
      </VCol>
    </VRow>

    <VRow
      v-if="showDescription"
      class="mt-n3"
    >
      <VCol
        data-test="video-item-description"
        class="tt-text-body-1"
      >
        <div class="video-description">
          {{ meta.description }}
        </div>
      </VCol>
    </VRow>

    <VRow
      class="mt-n1"
    >
      <VCol>
        <VResponsive
          :aspect-ratio="$options.VIDEO_ASPECT_RATIO"
        >
          <iframe
            v-if="videoSrc"
            class="video-item"
            type="text/html"
            allow="fullscreen"
            width="100%"
            height="100%"
            :src="videoSrc"
            data-test="video-item-iframe-video"
            data-test-value=""
          />
          <div
            v-else
            class="d-flex align-center fill-height justify-center"
          >
            <span
              data-test="video-item-video-cant-play"
              class="tt-text-subtitle"
            >{{ $t('items.video_cant_be_playing') }}</span>
          </div>
        </VResponsive>
        <VCol
          v-if="showLinkToTask"
          cols="12"
          class="pl-0 pt-2"
        >
          <RouterLink
            :to="{name : Names.R_APP_LEVEL_VIEW, params : {levelId : task.levelId,taskId : task.id}}"
            class="text-decoration-none"
            data-test="video-item-link-redirect"
          >
            {{ $t('button_text.goToTask') }}
          </RouterLink>
        </VCol>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import {
  VIDEO_TYPE_YOUTUBE, VIDEO_TYPE_VIMEO, VIDEO_TYPE_KINESCOPE, VIDEO_ASPECT_RATIO,
} from '@/components/items/constants';

export default {
  name: 'VideoItem',
  inject: ['Names'],
  props: {
    /**
     * @type {{
     * name: string,
     * description: string,
     * width: number,
     * height: number,
     * provider_name: string,
     * provider_id: string,
     * screenshot: {
        virgin_url: string,
        tint_url: string,
        width: number,
        height: number,
      },
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    result: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    showLinkToTask: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    videoSrc() {
      if (!this.meta?.provider_name) {
        return null;
      }
      const providerId = this.meta.provider_id;
      switch (this.meta?.provider_name) {
        case VIDEO_TYPE_YOUTUBE:
          return `https://www.youtube.com/embed/${providerId}?enablejsapi=1`;
        case VIDEO_TYPE_VIMEO:
          return `https://player.vimeo.com/video/${providerId}`;
        case VIDEO_TYPE_KINESCOPE:
          return `https://kinescope.io/embed/${providerId}`;
        default:
          return null;
      }
    },
  },
  created() {
    this.submit();
  },
  methods: {
    submit() {
      if (!this.result) {
        this.$emit('submit');
      }
    },
  },

  VIDEO_ASPECT_RATIO,
};
</script>

<style lang="scss" scoped>
.video-item {
  border: inherit;
  border-radius: 12px;
}
.video-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
