<template>
  <div>
    <VRow>
      <VCol
        cols="12"
        class="tt-text-title-2"
        data-test="question-item-question"
      >
        {{ meta.question }}
      </VCol>
    </VRow>

    <VRow class="mt-0">
      <VCol
        cols="12"
        class="tt-text-body-2 tt-light-mono-64--text"
      >
        {{ $t('components.choose_one') }}
      </VCol>
    </VRow>

    <VRow
      class="mt-1"
    >
      <VCol
        cols="12"
        class="px-4"
      >
        <VRadioGroup
          v-model="userAnswer"
          column
          :disabled="completed || isSkipped"
          hide-details
          class="mt-0 "
        >
          <template v-for="(item, index) in items">
            <TTRadio
              :key="`radio-${index}`"
              class="radio"
              :class="[getClasses(item, index), {'mb-4' : index < items.length - 1}]"
              :label="item.label"
              :value="index"
              :data-test-label="`question-item-radio-${index}`"
            />
            <VDivider
              v-if="index < items.length - 1"
              :key="`divider-${index}`"
              class="mb-4"
            />
          </template>
        </VRadioGroup>
      </VCol>
    </VRow>

    <VRow
      v-if="showComment"
      class="mt-1"
    >
      <VCol cols="12">
        <div
          class="message tt-light-mono-4 px-5 py-4"
        >
          <template v-if="!isVote">
            <span
              v-if="answerIsCorrect"
              data-test="question-item-correct-answer"
            >
              {{ meta.action.correct }}
            </span>
            <span
              v-else
              data-test="question-item-incorrect-answer"
            >
              {{ meta.action.incorrect }}
            </span>
          </template>
          <span
            v-if="isVote && voteComment"
            data-test="question-item-vote-comment"
          >
            {{ voteComment }}
          </span>
        </div>
      </VCol>
    </VRow>

    <ItemButtons
      v-if="!isSkipped && !completed"
      :disabled="disabled"
      :loading="loading"
      :can-be-skipped="canBeSkipped"
      :skip-button-show="!isSkipped"
      :submit-unlock="submitUnlock"
      :submit-button-show="!isSkipped"
      :submit-button-text="submitButtonText"
      data-test-submit="question-item-btn-submit"
      data-test-skip="question-item-btn-skip"
      @skip="skip"
      @submit="submit"
    />
  </div>
</template>

<script>
import ItemButtons from '@/components/items/ItemButtons.vue';

export default {
  name: 'QuestionItem',
  components: { ItemButtons },
  props: {
    /**
     * @type {{
     * question: string,
     * answer: number,
     * answers: {
     *   label: string,
     *   is_right: boolean,
     * },
     * action: {
     *   correct: string,
     *   incorrect: string,
     * }
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
    result: {
      type: Object,
      default: null,
    },
    // TODO Зачем это свойство? Оно не задается нигде, но используется в бизнес логике дефолтным значением?
    isVote: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSkipped: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userAnswer: null,
    };
  },
  computed: {
    showComment() {
      if (!this.completed) {
        return false;
      }
      const { action: { correct, incorrect } = {} } = this.meta;
      return correct || incorrect || this.voteComment;
    },
    rightAnswerIndex() {
      if (!this.isVote) {
        return this.rightAnswer.index;
      }
      return null;
    },
    rightAnswer() {
      if (!this.isVote) {
        return this.items.find((item) => item.is_right);
      }
      return this.items.find((item) => item.index === this.userAnswer);
    },
    answerIsCorrect() {
      return this.userAnswer === this.rightAnswerIndex;
    },
    completed() {
      return !!this.result;
    },
    submitUnlock() {
      return this.userAnswer !== null;
    },
    voteComment() {
      return this.rightAnswer.comment;
    },
    items() {
      return this.meta.answers.map((item, index) => ({ ...item, index }));
    },
    submitButtonText() {
      return this.isVote ? this.$t('items.submit') : this.$t('items.get_answer');
    },
  },
  watch: {
    result: {
      immediate: true,
      handler() {
        if (this.result) {
          this.userAnswer = this.isVote ? this.result.vote?.values[0] : this.result.selectOne?.value;
        }
      },
    },
  },
  methods: {
    submit() {
      const { isVote, userAnswer } = this;
      this.$emit('submit', isVote ? [userAnswer] : userAnswer);
    },
    skip() {
      this.userAnswer = null;
      this.$emit('skip');
    },
    getClasses(item, index) {
      if (!this.completed) { return null; }
      const { rightAnswerIndex, userAnswer, isVote } = this;
      return {
        'radio-success': isVote || (index === rightAnswerIndex),
        'radio-error': !isVote && index !== rightAnswerIndex && index === userAnswer,
        'radio-default': (isVote && index !== userAnswer) || (index !== rightAnswerIndex && index !== userAnswer),
      };
    },
  },
};
</script>

<style scoped lang="scss">

.message {
  border-radius: 12px;
}

::v-deep {

  .v-input__slot {
    margin-bottom: 0;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-radio.radio {
    &.radio-success.v-radio--is-disabled {
      .v-icon {
        color: map-get($tt-light-green, 'vibrant') !important;
      }
    }

    &.radio-error.v-radio--is-disabled {
      .v-icon {
        color: map-get($tt-light-orange, 'base') !important;
      }
    }
  }

  .radio {
    .radio-label {
      color: map-deep-get($tt-light-mono-100, 'base') !important;
    }

    &-success {
      .tt-radio {
        color: map-get($tt-light-green, 'vibrant') !important;
      }
    }

    &-error {
      .tt-radio {
        color: map-get($tt-light-orange, 'base') !important;
      }
    }

    &-default {
      .tt-radio {
        color: map-get($tt-light-mono-24, 'base') !important;
      }
    }
  }
}
</style>
