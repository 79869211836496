<template>
  <div>
    <VRow>
      <VCol cols="12">
        <h3
          data-test="text-answer-item-question"
          class="tt-text-title-2"
        >
          {{ meta.question }}
        </h3>
      </VCol>
    </VRow>
    <VRow class="mt-1">
      <VCol cols="12">
        <VForm
          ref="form"
          v-model="formValid"
          data-test="text-answer-item-form"
        >
          <TTTextarea
            ref="answer"
            v-model="answer"
            :rules="rules"
            :disabled="taskCompleted || (!edit && isSend) || loading || disabled || (isSkipped && !isSend)"
            :placeholder="$t('items.text_answer.placeholder')"
            :maxlength="false"
            auto-grow
            class="text-answer"
            data-test-label="text-answer-item-textarea"
          >
            <!-- eslint-disable vue/no-v-html -->
            <template #message="{ message }">
              <span
                data-test="text-answer-item-error"
                v-html="message"
              />
            </template>
            <!--eslint-enable-->
          </TTTextarea>
        </VForm>
      </VCol>
    </VRow>
    <template v-if="!isSkipped">
      <ItemButtons
        v-if="!taskCompleted"
        :disabled="disabled"
        :loading="loading"
        :can-be-skipped="canBeSkipped && !isSend"
        :submit-button-show="true"
        :submit-unlock="formValid && answer!==resultString"
        :submit-button-text="isSend && !edit ? $t('items.submitted') : $t('items.submit')"
        data-test-submit="text-answer-item-btn-submit"
        data-test-skip="text-answer-item-btn-skip"
        @skip="skip"
        @submit="submit"
      >
        <template #custom>
          <TTBtn
            v-if="isSend && formValid && !edit"
            class="text-answer-edit-btn"
            color="tt-secondary"
            large
            data-test-label="text-answer-item-btn-edit"
            @click="editAnswer"
          >
            <VIcon>
              fal fa-pen
            </VIcon>
          </TTBtn>
        </template>
      </ItemButtons>
    </template>
  </div>
</template>

<script>
import ItemButtons from '@/components/items/ItemButtons.vue';
import getValidators from '@/helpers/getValidators';

export default {
  name: 'TextAnswerItem',
  components: { ItemButtons },
  props: {
    /**
     * @type {{
     * question: string,
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
    result: {
      type: [Object, null],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSkipped: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    taskCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      answer: '',
      formValid: false,
      edit: false,
    };
  },
  computed: {
    rules() {
      const { validate } = this.meta;
      if (validate) {
        return getValidators(validate);
      }
      return [];
    },
    isSend() {
      return !!this.result;
    },
    resultString() {
      if (this.result) {
        return this.result?.enterString?.value || '';
      }
      return '';
    },
  },
  watch: {
    result: {
      immediate: true,
      handler: 'setResult',
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.answer);
    },
    skip() {
      this.answer = '';
      this.$refs.form.resetValidation();
      this.$emit('skip');
    },
    editAnswer() {
      if (!this.edit) {
        this.edit = true;
        this.$nextTick(() => {
          this.$refs.answer.$el.querySelector('textarea').focus();
        });
      } else {
        this.edit = false;
      }
    },
    setResult() {
      if (this.result && this.resultString) {
        this.edit = false;
        this.answer = this.resultString;
      }
    },
  },
};
</script>

<style scoped lang="scss">

.text-answer {
  ::v-deep {
    textarea {
      height: 124px;
    }
  }
}

.text-answer-edit-btn {
  min-width: auto !important;
  max-width: 44px;
}

::v-deep .tt-textarea .v-input--is-disabled textarea,
::v-deep .theme--light.v-input--is-disabled input{
    color: map-get($tt-light-mono-100, 'base') !important;
}
</style>
