<template>
  <div>
    <VRow>
      <VCol
        data-test="document-item-filename"
        class="tt-text-title-2"
      >
        {{ meta.filename }}
      </VCol>
    </VRow>

    <VRow class="mt-n4">
      <VCol
        data-test="document-item-description"
        class="tt-text-body-1"
      >
        {{ meta.description }}
      </VCol>
    </VRow>

    <VRow class="mt-1">
      <VCol>
        <a
          :href="meta.file"
          :download="meta.filename"
          class="file d-flex pa-2 ma-n2"
          data-test="document-item-link"
          @click="submit"
        >
          <span
            class="file-icon mr-3 d-flex justify-center align-center pale"
            :class="meta.mime === 'application/pdf' ? 'tt-light-red' : 'tt-light-blue'"
          >
            <VIcon
              v-if="meta.mime==='application/pdf'"
              class="icon tt-light-red--text"
              data-test="document-item-icon-pdf"
            >
              fal fa-file-pdf
            </VIcon>
            <VIcon
              v-else
              class="icon tt-light-blue--text"
            >
              fal fa-arrow-to-bottom
            </VIcon>
          </span>
          <span class="d-block">
            <div
              data-test="document-item-link-filename"
              class="tt-text-body-1 tt-light-mono-100--text"
            >
              {{ meta.filename }}
            </div>
            <div
              data-test="document-item-link-size"
              class="tt-text-body-2 tt-light-mono-46--text"
            >
              {{ meta.size }}
            </div>
          </span>
        </a>
      </VCol>
    </VRow>
  </div>
</template>

<script>
// TODO: mime вынести в константы, цвета вынести в константы

export default {
  name: 'DocumentItem',
  props: {
    /**
     * @type {{
     * file: string,
     * filename: string,
     * description: string,
     * type: string,
     * size: string,
     * mime: string,
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
    result: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      if (!this.result) {
        this.$emit('submit');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.file {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 8px;

  &:hover {
    background: rgba(#ccc, 0.1);
  }

  &-icon {
    border-radius: 8px;
    width: 40px;
    height: 40px;
  }
}

.icon {
  width: 24px;
  height: 24px;
}
</style>
