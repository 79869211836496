<template>
  <div>
    <VRow v-if="!!meta.title">
      <VCol
        data-test="offer-item-title"
        class="tt-text-title-2"
      >
        {{ meta.title }}
      </VCol>
    </VRow>
    <VRow
      v-if="!!meta.description"
      class="mt-n5"
    >
      <VCol
        data-test="offer-item-description"
        class="tt-text-body-1"
      >
        {{ meta.description }}
      </VCol>
    </VRow>
    <template v-if="!!meta.position">
      <VRow class="mt-1">
        <VCol
          class="tt-text-title-2"
          data-test="offer-item-title-position"
        >
          {{ $t('items.offer.position') }}
        </VCol>
      </VRow>
      <VRow class="mt-n5">
        <VCol
          data-test="offer-item-position"
          class="tt-text-body-1"
        >
          {{ meta.position }}
        </VCol>
      </VRow>
    </template>
    <template v-if="!!formattedStartedDay">
      <VRow class="mt-1">
        <VCol
          class="tt-text-title-2"
          data-test="offer-item-title-start-date"
        >
          {{ $t('items.offer.start_date') }}
        </VCol>
      </VRow>
      <VRow class="mt-n5">
        <VCol
          data-test="offer-item-started-day"
          class="tt-text-body-1"
        >
          {{ formattedStartedDay }}
        </VCol>
      </VRow>
    </template>
    <template v-if="!!formattedVocationDays">
      <VRow class="mt-1">
        <VCol
          data-test="offer-item-title-vocation"
          class="tt-text-title-2"
        >
          {{ $t('items.offer.vocation') }}
        </VCol>
      </VRow>
      <VRow class="mt-n5">
        <VCol
          data-test="offer-item-vocation-days"
          class="tt-text-body-1"
        >
          {{ formattedVocationDays }}
        </VCol>
      </VRow>
    </template>
    <template v-if="!!meta.work_conditions">
      <VRow class="mt-1">
        <VCol
          class="tt-text-title-2"
          data-test="offer-item-title-work-conditions"
        >
          {{ $t('items.offer.work_conditions') }}
        </VCol>
      </VRow>
      <VRow class="mt-n5">
        <VCol
          data-test="offer-item-work-conditions"
          class="tt-text-body-1"
        >
          {{ meta.work_conditions }}
        </VCol>
      </VRow>
    </template>
    <template v-if="formattedSalary">
      <VRow class="mt-1">
        <VCol
          class="tt-text-title-2"
          data-test="offer-item-title-salary"
        >
          {{ $t('items.offer.salary') }}
        </VCol>
      </VRow>
      <VRow class="mt-n5">
        <VCol
          data-test="offer-item-salary"
          class="tt-text-body-1"
        >
          {{ formattedSalary }}
        </VCol>
      </VRow>
    </template>
  </div>
</template>

<script>

import { currencyFormatter } from '@/helpers/stringHelpers';
import { formatDate } from '@/helpers/dateHelpers';

export default {
  name: 'OfferItem',
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formattedStartedDay() {
      if (!this.meta.started) {
        return null;
      }
      return formatDate(this.meta.started, 'LLL');
    },

    formattedVocationDays() {
      if (!this.meta.vacation_days) {
        return null;
      }
      return this.$tc('pluralize.vocationDays', this.meta.vacation_days);
    },

    formattedSalary() {
      if (!this.meta.salary) {
        return null;
      }
      return currencyFormatter(this.meta.salary);
    },
  },
};
</script>
