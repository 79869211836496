<template>
  <div class="word-item">
    <VRow>
      <VCol
        cols="12"
        class="tt-text-title-2"
        data-test="word-item-question"
      >
        {{ meta.question }}
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <VOtpInput
          v-model="chars"
          :readonly="readonly"
          :success="isRightAnswer && (completed || showResult)"
          :error="!isRightAnswer && (completed || showResult)"
          :length="$options.WORD_ITEM_LEN"
          class="word-item tt-text-title-2"
          data-test="word-item-text-field"
        />
      </VCol>
    </VRow>

    <VRow
      v-if="(completed || showResult) && !isQR &&
        ((isRightAnswer && meta.action.right) || (!isRightAnswer && meta.action.wrong))"
      class="mt-1"
    >
      <VCol cols="12">
        <div
          class="message tt-light-mono-4 px-5 py-4"
          :data-test="'word-item-message-' + (isRightAnswer ? 'right' : 'wrong')"
        >
          <template v-if="isRightAnswer">
            {{ meta.action.right }}
          </template>
          <template v-else>
            {{ meta.action.wrong }}
          </template>
        </div>
      </VCol>
    </VRow>

    <template v-if="!isSkipped && !completed">
      <ItemButtons
        v-if="!(isRightAnswer && (completed || showResult))"
        :disabled="isDisabled"
        :loading="loading"
        :can-be-skipped="canBeSkipped && !showResult"
        :skip-button-show="!isSkipped"
        :submit-unlock="submitUnlock"
        :submit-button-show="!(isRightAnswer && (completed || showResult))"
        data-test-submit="word-item-btn-submit"
        data-test-skip="word-item-btn-skip"
        @skip="skip"
        @submit="submit"
      >
        <template #custom>
          <TTBtn
            v-if="isDisabled"
            color="tt-secondary"
            class="edit-btn"
            large
            data-test-label="word-item-btn-edit"
            @click="editAnswer"
          >
            <VIcon>
              fal fa-pen
            </VIcon>
          </TTBtn>
        </template>
      </ItemButtons>
    </template>
  </div>
</template>

<script>
import ItemButtons from '@/components/items/ItemButtons.vue';
import { WORD_ITEM_LEN } from '@/components/items/constants';

export default {
  name: 'WordItem',
  components: { ItemButtons },
  props: {
    /**
     * @type {{
     * question: string,
     * validate: {
     *   text: string,
     *   code: string,
     * }
     * action: {
     *   right: string,
     *   wrong: string,
     * }
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
    result: {
      type: [Object, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSkipped: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isQR: {
      type: Boolean,
      default: false,
    },
  },
  WORD_ITEM_LEN,
  data() {
    return {
      showResult: false,
      chars: '',
    };
  },
  computed: {
    readonly() {
      return this.completed || this.showResult || this.isSkipped;
    },
    completed() {
      return !!this.result;
    },
    submitUnlock() {
      return this.chars.length === WORD_ITEM_LEN;
    },
    userAnswer() {
      return this.chars.length > 0 ? this.chars : '';
    },
    shortenedAnswer() {
      return this.rightAnswerFromValidate.slice(0, WORD_ITEM_LEN);
    },
    rightAnswerFromValidate() {
      if (!this.isQR) {
        return this.meta.validate.text;
      }
      return this.meta.validate.code;
    },
    isRightAnswer() {
      return this.userAnswer === this.shortenedAnswer;
    },
    isDisabled() {
      return this.disabled || (!this.isRightAnswer && (this.completed || this.showResult));
    },
  },
  watch: {
    result: {
      immediate: true,
      handler: 'getResult',
    },
  },
  methods: {
    getResult() {
      // У бэка есть баг, ONE_WORD result приходит пустое значение, по-этому берем из правильного ответа
      // для qr в web версии вводим ограничение на 5 символов, по-этому берем из правильного ответа
      if (this.completed) {
        this.chars = this.shortenedAnswer;
      }
    },
    submit() {
      if (this.userAnswer.toUpperCase() === this.shortenedAnswer.toUpperCase()) {
        this.$emit('submit', this.rightAnswerFromValidate);
      } else {
        this.showResult = true;
      }
    },
    skip() {
      this.chars = '';
      this.$emit('skip');
    },
    editAnswer() {
      this.showResult = false;
    },
  },
};
</script>

<style scoped lang="scss">

::v-deep {

  .word-item {
    input {
      caret-color: map-get($tt-light-mono-100, 'base');
    }

    .v-input {
      &__slot {
        background-color: map-get($tt-light-mono-4, 'base') !important;

        fieldset {
          border: 1px solid map-get($tt-light-mono-8, 'base');
        }
      }
    }

    .success--text {
      fieldset {
        border: 1px solid map-get($tt-light-green, 'vibrant')
      }
    }

    .error--text {
      fieldset {
        border: 1px solid #EF323F !important;
      }
    }

    .v-input--is-focused {
      fieldset {
        border: 1px solid map-get($tt-light-mono-16, 'base');
      }

      &.success--text {
        fieldset {
          border: 1px solid map-get($tt-light-green, 'vibrant') !important;
        }
      }

      &.error--text {
        fieldset {
          border: 1px solid #EF323F !important;
        }
      }
    }

    .character {

      input {
        text-align: center;
        font-size: 1.25rem !important;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.3px !important;
        font-family: "Roboto", sans-serif !important;
      }

    }
  }

}

.message {
  border-radius: 12px;
}

.edit-btn {
  min-width: auto !important;
  max-width: 44px;
}

</style>
