<template>
  <div>
    <VRow>
      <VCol cols="12">
        <ItemWrapper
          class="pa-0"
        >
          <VResponsive :aspect-ratio="$options.MAP_ASPECT_RATIO">
            <YandexMap
              ref="map"
              :settings="settings"
              :coords="coordinates"
              :zoom="$options.MAP_ZOOM"
              :controls="['zoomControl', 'routeButtonControl']"
              ymap-class="map-container"
              data-test="map-item-yandex-map"
              data-test-value=""
            >
              <YMapMarker
                marker-id="main"
                :coords="coordinates"
                :balloon="address"
              />
            </YandexMap>
          </VResponsive>
        </ItemWrapper>
      </VCol>
    </VRow>
    <VRow v-if="$vuetify.breakpoint.xsOnly">
      <VCol>
        <span
          class="tt-light-mono-64--text tt-text-body-1"
          data-test-label="map-item-address"
        >{{ meta.address }}</span>
      </VCol>
    </VRow>
    <ItemButtons
      class="mt-1"
      :submit-button-text="$t('items.open')"
      :submit-button-color="$options.MAP_SUBMIT_BUTTON_COLOR"
      :submit-button-show="true"
      data-test-submit="map-item-btn-open"
      data-test-skip="map-item-btn-skip"
      @submit="openMap"
    >
      <template
        v-if="!$vuetify.breakpoint.xsOnly"
        #custom
      >
        <span
          class="tt-light-mono-64--text tt-text-body-1"
          data-test-label="map-item-address"
        >{{ meta.address }}</span>
      </template>
    </ItemButtons>
  </div>
</template>

<script>
import { yandexMap as YandexMap, ymapMarker as YMapMarker } from 'vue-yandex-maps';
import { MAP_ZOOM, MAP_ASPECT_RATIO, MAP_SUBMIT_BUTTON_COLOR } from '@/components/items/constants';
import ItemWrapper from '@/components/task/ItemWrapper.vue';
import ItemButtons from '@/components/items/ItemButtons.vue';

export default {
  name: 'MapItem',
  components: {
    ItemButtons,
    ItemWrapper,
    YandexMap,
    YMapMarker,
  },
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
  },

  MAP_ZOOM,
  MAP_ASPECT_RATIO,
  MAP_SUBMIT_BUTTON_COLOR,

  data() {
    return {
      settings: {
        // TODO: Бесплатная захардкоженая версия, заменить на платную как ее получим
        apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY,
      },
    };
  },

  computed: {
    address() {
      return {
        body: this.meta.address,
      };
    },
    coordinates() {
      if (!this.meta.position) {
        return [55.791977, 37.603244];
      }
      const { lat, lng } = this.meta.position;
      return [lat, lng];
    },
  },

  methods: {
    openMap() {
      const button = this.$refs.map.$el.querySelector('.ymaps-2-1-79-gotoymaps');
      button.click();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .map-container, .ymap-container {
    height: 100%;
    width: 100%;
  }
}
</style>
