<template>
  <VList
    three-line
    class="pa-0"
  >
    <VListItem class="align-start pa-0">
      <VListItemAvatar class="my-0">
        <TTAvatar
          class="contact-avatar"
          :text="imageText"
          :src="photoURL"
          data-test-label="contacts-item-avatar"
        />
      </VListItemAvatar>
      <VListItemContent class="pa-0 align-self-start">
        <VListItemTitle
          class="text-wrap tt-text-body-1"
          data-test="contacts-item-full-name"
        >
          {{ title }}
        </VListItemTitle>
        <VListItemSubtitle>
          <VRow no-gutters>
            <VCol
              cols="12"
              class="tt-light-mono-46--text tt-text-body-2"
            >
              <div
                v-if="meta.position"
                data-test="contacts-item-position"
              >
                {{ meta.position }}
              </div>
              <div v-if="meta.phone">
                <a
                  data-test="contacts-item-phone"
                  class="text-decoration-underline tt-light-mono-46--text"
                  :href="`tel:${meta.phone}`"
                >
                  {{ meta.phone }}
                </a>
              </div>
              <div v-if="meta.email">
                <a
                  data-test="contacts-item-email"
                  class="text-decoration-underline tt-light-mono-46--text"
                  :href="`mailto:${meta.email}`"
                >
                  {{ meta.email }}
                </a>
              </div>
            </VCol>
          </VRow>
        </VListItemSubtitle>
      </VListItemContent>
    </VListItem>
    <VRow
      v-if="showLinkToTask"
      no-gutters
    >
      <VCol
        cols="12"
        class="pl-13 pt-2"
      >
        <RouterLink
          :to="{name : Names.R_APP_LEVEL_VIEW, params : {levelId : task.levelId,taskId : task.id}}"
          class="text-decoration-none"
          data-test="file-card-link-redirect"
        >
          {{ $t('button_text.goToTask') }}
        </RouterLink>
      </VCol>
    </VRow>
  </VList>
</template>

<script>
import { cutString } from '@/helpers/stringHelpers';

export default {
  name: 'ContactsItem',
  inject: ['Names'],
  props: {
    /**
     * @type {{
     * first_name: string,
     * last_name: string,
     * middle_name: string,
     * position: string,
     * phone: string,
     * email: string,
     * photo_url: string,
     * }}
     */
    meta: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    showLinkToTask: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullName() {
      return `${this.meta.last_name} ${this.meta.first_name} ${this.meta.middle_name}`;
    },
    title() {
      return cutString(this.fullName, 45);
    },
    imageText() {
      return `${this.meta.first_name.slice(0, 1)}${this.meta.last_name.slice(0, 1)}`;
    },
    photoURL() {
      return this.meta?.photo_url;
    },
  },
};
</script>

<style scoped lang="scss">
.contact {
  box-shadow: 0 2px 24px 0 rgba(11, 18, 24, 0.08) !important;

  &-avatar{
    width: 40px;
    height: 40px;
  }
}
</style>
