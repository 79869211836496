<template>
  <div>
    <VRow>
      <VCol
        data-test="question-multiple-item-question"
        class="tt-text-title-2"
      >
        {{ meta.question }}
      </VCol>
    </VRow>

    <VRow class="mt-n5">
      <VCol class="tt-text-body-2 tt-light-mono-64--text">
        {{ $t('components.choose_one_or_many') }}
      </VCol>
    </VRow>

    <VRow class="mt-n4 mb-n7">
      <VCol class="px-4">
        <template v-for="(item, index) in items">
          <TTCheckbox
            :key="`checkbox-${index}`"
            v-model="userAnswer"
            class="checkbox mb-4"
            :disabled="completed || isSkipped"
            :class="getClasses(item)"
            :label="item.label"
            :value="index"
            :data-test-label="`question-multiple-checkbox-${index}`"
            :on-icon="completed && !item.is_right && item.value && !isVote
              ? 'far fa-times'
              : undefined"
          />
          <VDivider
            v-if="index < items.length - 1"
            :key="`divider-${index}`"
            class="mb-4"
          />
        </template>
      </VCol>
    </VRow>

    <VRow
      v-if="showComment"
      class="mt-1"
    >
      <VCol>
        <div
          class="message tt-light-mono-4 px-5 py-4"
        >
          <template v-if="!isVote">
            <span
              v-if="allIsCorrect"
              data-test="question-multiple-item-all-is-correct"
            >
              {{ meta.action.correct }}
            </span>
            <span
              v-else
              data-test="question-multiple-item-incorrect"
            >
              {{ meta.action.incorrect }}
            </span>
          </template>
          <template v-else>
            <span
              v-if="userAnswer.length===1 && voteComments[0]"
              data-test="question-multiple-item-comment"
            >
              {{ voteComments[0] }}
            </span>
            <span
              v-else-if="meta.multi_comment"
              data-test="question-multiple-item-comments"
            >
              {{ meta.multi_comment }}
            </span>
          </template>
        </div>
      </VCol>
    </VRow>

    <ItemButtons
      v-if="!isSkipped && !completed"
      :disabled="disabled"
      :loading="loading"
      :can-be-skipped="canBeSkipped"
      :skip-button-show="!isSkipped"
      :submit-unlock="submitUnlock"
      :submit-button-show="!isSkipped"
      :submit-button-text="isVote ? $t('items.submit') : $t('items.get_answer')"
      data-test-submit="question-multiple-item-btn-submit"
      data-test-skip="question-multiple-item-btn-skip"
      @skip="skip"
      @submit="submit"
    />
  </div>
</template>

<script>
import ItemButtons from '@/components/items/ItemButtons.vue';

export default {
  name: 'QuestionMultipleItem',
  components: { ItemButtons },
  props: {
    /**
     * @type {{
     * question: string,
     * answers: {
     *   label: string,
     *   is_right: boolean,
     * },
     * action: {
     *   correct: string,
     *   incorrect: string,
     * },
     * multi_comment: string
     * }}
     */
    meta: {
      type: Object,
      required: true,
      // default: () => ({}),
    },
    /**
     * @type {{
     * selectMany?: {
     *   values: Array<number>,
     * }
     * }}
     */
    result: {
      type: Object,
      default: null,
    },
    isVote: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSkipped: {
      type: Boolean,
      default: false,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userAnswer: [],
    };
  },
  computed: {
    showComment() {
      if (!this.completed) {
        return false;
      }
      const { action: { correct, incorrect } = {}, multi_comment: multiComment = false } = this.meta;
      return correct || incorrect || multiComment || this.voteComments.length;
    },
    completed() {
      return !!this.result;
    },
    submitUnlock() {
      return !!this.items.find((item) => item.value);
    },
    allIsCorrect() {
      if (!this.submitUnlock) {
        return false;
      }
      return this.items.every((item) => (item.is_right === item.value));
    },
    items() {
      return this.meta.answers.map((item, index) => ({ ...item, value: this.userAnswer.includes(index) }));
    },
    voteComments() {
      return this.items.reduce((acc, item) => {
        if (item.value && item.comment) {
          acc.push(item.comment);
        }
        return acc;
      }, []);
    },
  },
  watch: {
    result: {
      immediate: true,
      handler() {
        if (this.result) {
          this.userAnswer = this.isVote ? this.result.vote?.values : this.result.selectMany?.values;
        }
      },
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.userAnswer);
    },
    skip() {
      this.userAnswer = [];
      this.$emit('skip');
    },
    getClasses(item) {
      if (!this.completed) {
        return null;
      }
      return {
        checkbox_success: item.is_right || this.isVote,
        checkbox_error: !item.is_right && item.value && !this.isVote,
        checkbox_default:
          (this.isVote && !item.value) || (!item.is_right && !item.value),
      };
    },
  },
};
</script>

<style scoped lang="scss">

.message {
  border-radius: 12px;
}

::v-deep {
  .checkbox.v-input.v-input--selection-controls.v-input--checkbox {
    .v-icon {
      border-radius: 4px
    }
  }

  .checkbox.checkbox_success.v-input.v-input--selection-controls.v-input--checkbox {
    .v-icon {
      color: map-get($tt-light-green, 'vibrant') !important;
    }
  }

  .checkbox.checkbox_error.v-input.v-input--selection-controls.v-input--checkbox {
    .v-icon {
      color: map-get($tt-dark-mono-100, 'base') !important;
      background: map-get($tt-light-orange, 'base') !important;
    }
  }

  .checkbox.checkbox_default.v-input.v-input--selection-controls.v-input--checkbox {
    .v-icon {
      color: map-get($tt-light-mono-24, 'base') !important;
    }
  }

  .checkbox-label {
    color: map-get($tt-dark-mono-0, 'base') !important;
  }
}
</style>
