<template>
  <VContainer
    fluid
    class="position-sticky--top pa-0 text-center"
  >
    <VRow
      justify="center"
      dense
    >
      <VCol
        v-if="showButton('close')"
        cols="auto"
      >
        <TTBtn
          icon
          fab
          elevation="1"
          depressed
          large
          color="tt-light-mono-0"
          data-test-label="btn-ask-close-task"
          @click="onCloseClick"
        >
          <VIcon color="tt-light-mono-100">
            fal fa-times
          </VIcon>
        </TTBtn>
        <div
          class="tt-light-mono-46--text mt-2 tt-text-footnote"
        >
          {{ $t('close') }}
        </div>
      </VCol>
      <VCol
        v-if="showButton('ask')"
        cols="auto"
        class="text-center"
      >
        <VDialog
          v-model="isDialogOpen"
          width="576"
          @click:outside="closeQuestion"
        >
          <template #activator="{ on, attrs }">
            <div>
              <TTBtn
                icon
                fab
                large
                elevation="1"
                depressed
                color="tt-light-mono-0"
                v-bind="attrs"
                data-test-label="btn-ask-question-task"
                v-on="on"
              >
                <VIcon color="tt-light-mono-100">
                  fal fa-question-circle
                </VIcon>
              </TTBtn>
              <div
                class="tt-light-mono-46--text mt-2 tt-text-footnote"
              >
                {{ $t('ask') }}<br>
                {{ $t('question') }}
              </div>
            </div>
          </template>

          <VCard
            class="ask-question-container pa-6"
            data-test="ask-question-card"
          >
            <div class="mb-6 d-flex justify-space-between">
              <div
                data-test="ask-question-title"
                class="tt-text-title-2"
              >
                {{ $t('ask') }} {{ $t('question') }}
              </div>

              <TTBtn
                icon
                class="ask-question-btn-close"
                data-test-label="ask-question-button-close"
                @click="closeQuestion"
              >
                <VIcon color="tt-light-mono-100">
                  fal fa-times
                </VIcon>
              </TTBtn>
            </div>

            <TTTextarea
              ref="question"
              v-model="question"
              :maxlength="false"
              :disabled="loading"
              class="ask-question-text mb-2"
              autofocus
              data-test-label="ask-question-textarea"
            />

            <div
              data-test="ask-question-text-validate"
              class="tt-text-body-2 tt-light-mono-46--text mb-6"
            >
              {{ $t('validators.not') }}
              {{ $t('validators.less') }}
              {{ $tc('validators.char', $options.QUESTION_MIN_LENGTH) }}
            </div>

            <VRow>
              <VCol
                cols="6"
              >
                <TTBtn
                  class="ask-question-dialog-btn"
                  color="tt-secondary"
                  large
                  block
                  data-test-label="ask-question-btn-cancel"
                  @click="closeQuestion"
                >
                  {{ $t('cancel') }}
                </TTBtn>
              </VCol>
              <VCol
                cols="6"
              >
                <TTBtn
                  class="ask-question-dialog-btn"
                  :disabled="question.length < $options.QUESTION_MIN_LENGTH"
                  :loading="loading"
                  primary
                  large
                  block
                  data-test-label="ask-question-btn-submit"
                  @click="sendQuestion"
                >
                  {{ $t('items.text_answer.send') }}
                </TTBtn>
              </VCol>
            </VRow>
          </VCard>
        </VDialog>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import { toggleSnack } from '@/plugins/vuex/mutationTypes';
import * as snamiApi from '@/services/api/snami';
import { SET_HAPPY_STATE_STATES_ACTION } from '@/plugins/vuex/actionTypes';
import { entityType } from '@/helpers/constants';

export default {
  name: 'AskQuestion',

  inject: ['Names'],

  props: {
    buttons: {
      type: [Array],
      default: () => ['close', 'ask'],
    },
    taskId: {
      type: [String, Number],
      default: null,
    },
    entityType: {
      type: [String, Number],
      validator: (v) => Object.values(entityType).includes(v),
      default: entityType.NOT_SET,
    },
  },

  data() {
    return {
      question: '',
      loading: false,
      isDialogOpen: false,
    };
  },

  QUESTION_MIN_LENGTH: 10,
  methods: {
    ...mapMutations([toggleSnack]),
    ...mapActions('happyState', { setHappyStateStatesAction: SET_HAPPY_STATE_STATES_ACTION }),
    showButton(buttonName) {
      return this.buttons.includes(buttonName);
    },
    closeQuestion() {
      this.isDialogOpen = false;
      this.question = '';
    },
    onCloseClick() {
      this.$emit('close');
    },
    // Вынести метод за пределы компонента, здесь оставить только emit события?
    async sendQuestion() {
      try {
        this.loading = true;
        const data = {
          entityType: this.entityType,
          problem: this.question,
          taskId: this.taskId,
        };
        const { data: { happyState } } = await snamiApi.appealCreate({ data });
        this.setHappyStateStatesAction({ states: happyState });
        this.toggleSnack({
          message: this.$t('question_sent'),
          status: 'success',
        });
        this.closeQuestion();
      } catch (e) {
        const errorMessage = e.response?.error?.message || this.$t('request_error');

        this.toggleSnack({
          message: errorMessage,
          status: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
