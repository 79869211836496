<template>
  <div>
    <VRow>
      <VCol
        cols="12"
        class="tt-text-title-2"
        data-test="yes-or-no-item-question"
      >
        {{ meta.question }}
      </VCol>
    </VRow>

    <VRow
      v-if="meta.description"
      class="mt-0"
    >
      <VCol
        cols="12"
        class="tt-text-body-2 tt-light-mono-64--text"
        data-test="yes-or-no-item-description"
      >
        {{ meta.description }}
      </VCol>
    </VRow>

    <VRow
      class="mt-1"
    >
      <VCol
        cols="12"
        class="px-4"
      >
        <VRadioGroup
          v-model="userAnswer"
          column
          :disabled="completed || isSkipped"
          hide-details
          class="mt-0"
        >
          <TTRadio
            class="radio mb-4"
            :class="userAnswered && rightAnswer === userAnswer ? 'radio-picked' : null"
            :label="$t('items.yes')"
            color="tt-light-blue"
            :value="true"
            data-test-label="yes-or-no-item-radio-yes"
          />
          <VDivider
            class="mb-4"
          />
          <TTRadio
            class="radio"
            :class="userAnswered && rightAnswer === userAnswer ? 'radio-picked' : null"
            :label="$t('items.no')"
            color="tt-light-blue"
            :value="false"
            data-test-label="yes-or-no-item-radio-no"
          />
        </VRadioGroup>
      </VCol>
    </VRow>

    <VRow
      v-if="completed && comment"
      class="mt-1"
    >
      <VCol
        cols="12"
      >
        <div
          data-test="yes-or-no-item-comment"
          class="message tt-light-mono-4 px-5 py-4"
        >
          {{ comment }}
        </div>
      </VCol>
    </VRow>

    <ItemButtons
      v-if="!isSkipped && !completed"
      :disabled="disabled"
      :loading="loading"
      :can-be-skipped="canBeSkipped"
      :skip-button-show="!isSkipped"
      :submit-unlock="submitUnlock"
      :submit-button-show="!isSkipped"
      :submit-button-text="$t('items.text_answer.send')"
      data-test-submit="yes-or-no-item-btn-submit"
      data-test-skip="yes-or-no-item-btn-skip"
      @skip="skip"
      @submit="submit"
    />
  </div>
</template>

<script>
import ItemButtons from '@/components/items/ItemButtons.vue';

export default {
  name: 'YesOrNoItem',
  components: { ItemButtons },
  props: {
    /**
     * @type {{
     * question: string,
     * description: string,
     * answer: number,
     * action: {
     *   yes_text: string,
     *   no_text: string,
     * }
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
    result: {
      type: [Object, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSkipped: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userAnswer: null,
    };
  },
  computed: {
    completed() {
      return !!this.result;
    },
    submitUnlock() {
      return this.userAnswer !== null;
    },
    userAnswered() {
      return this.result?.yesNo;
    },
    rightAnswer() {
      return this.result?.yesNo?.value;
    },
    comment() {
      if (this.userAnswered && this.userAnswer) {
        return this.meta.action.yes_text;
      }
      return this.meta.action.no_text;
    },
  },
  watch: {
    result: {
      immediate: true,
      handler() {
        if (this.result) {
          this.userAnswer = this.result.yesNo?.value;
        }
      },
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.userAnswer);
    },
    skip() {
      this.userAnswer = null;
      this.$emit('skip');
    },
  },
};
</script>

<style scoped lang="scss">

.message {
  border-radius: 12px;
}

::v-deep {

  .v-input__slot {
    margin-bottom: 0;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-radio.radio {
    &.radio-picked.v-radio--is-disabled {
      .v-icon {
        color: map-get($tt-light-blue, 'base') !important;
      }
    }
  }

  .radio {
    .radio-label {
      color: map-deep-get($tt-light-mono-100, 'base') !important;
    }

    &-picked {
      .tt-radio {
        color: map-get($tt-light-blue, 'base') !important;
      }
    }
  }
}
</style>
