<template>
  <VRow>
    <VCol cols="auto">
      <CardWrapper shadow="always">
        <div class="image__container">
          <img
            :class="{cursor : !error && !loading}"
            :src="meta.file"
            data-test="image-task-item-img"
            @click="openDialog"
            @error="error=true"
            @load="load"
          >
          <VDialog
            v-model="dialog"
            :overlay-opacity="0.9"
            content-class="no-shadow"
          >
            <VImg
              max-height="90vh"
              contain
              class="cursor"
              :src="meta.file"
              @click="closeDialog"
            />
          </VDialog>
        </div>
      </CardWrapper>
    </VCol>
  </VRow>
</template>

<script>
import CardWrapper from '@/components/cards/CardWrapper.vue';

export default {
  name: 'ImageTaskItem',
  components: { CardWrapper },
  props: {
    /**
     * @type {{
     * file: string,
     * width?: number,
     * height?: number,
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      error: false,
      loading: true,
    };
  },
  methods: {
    load() {
      this.loading = false;
      this.error = false;
    },
    openDialog() {
      const { error, loading } = this;
      if (!error && !loading) {
        this.dialog = true;
      }
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.image__container{
  width: 100%;
  max-height: 718px;
  display: flex;
  img{
    max-height: inherit;
    max-width: 100%;
    justify-self: self-start;
    border-radius: inherit;

  }
}
.cursor{
  cursor: pointer;
}
::v-deep .no-shadow{
  box-shadow: none;
}
</style>
