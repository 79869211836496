<template>
  <VContainer class="pa-0">
    <VRow dense>
      <VCol
        cols="12"
        data-test="image-form-item-question"
        class="tt-text-title-2"
      >
        {{ meta.question }}
      </VCol>
      <VCol
        v-if="!isSkipped"
        cols="12"
      >
        <div
          class="upload-image"
          :class="{ 'upload-image_compact' : compact }"
        >
          <div>
            <VContainer
              ref="dropzone"
              fluid
              class="pa-0 upload-image__wrapper"
              :class="{
                'upload-image__wrapper_dragover' : dragover,
                'upload-image__wrapper_compact' : compact,
                'mb-3' : !compact
              }"
              data-test="image-form-item-dropzone"
              @drop.prevent.stop="handleDrag"
              @dragover.prevent.stop="dragover = true"
              @dragleave.prevent.stop="dragover = false"
            >
              <template v-if="!loading">
                <VRow
                  v-if="!image"
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <div
                    v-if="!errorMsg"
                    class="text-center"
                  >
                    <VIcon
                      size="19"
                      color="tt-light-mono-16"
                    >
                      fal fa-image
                    </VIcon>
                    <template v-if="!compact">
                      <div class="upload-image__format tt-light-mono-46--text mt-3">
                        {{ $t('common.upload_file') }} {{ $t('files.type.jpeg') }},
                        {{ $t('files.type.png') }} {{ $t('common.or') }} {{ $t('files.type.gif') }}
                        <br>{{ $t('common.in_this_field') }}
                      </div>
                      <div class="tt-text-body-2 tt-light-mono-46--text">
                        {{ image ? fileName : `${$t('files.max_size')} - ${$tc('files.size.mb',15)}` }}
                      </div>
                    </template>
                  </div>
                  <div
                    v-else
                    class="text-center"
                  >
                    <VIcon
                      size="19"
                      color="error"
                    >
                      fal fa-exclamation-circle
                    </VIcon>
                  </div>
                </VRow>
                <VRow
                  v-else
                >
                  <VCol class="py-0">
                    <VImg
                      class="background-img"
                      :src="image"
                      data-test="image-form-item-background-img"
                    >
                      <template #placeholder>
                        <VRow
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <VProgressCircular
                            indeterminate
                            color="grey"
                            data-test="image-form-item-progress-circular"
                          />
                        </VRow>
                      </template>
                    </VImg>
                  </VCol>
                </VRow>
              </template>
            </VContainer>
          </div>
          <div
            v-if="errorMsg"
            class="upload-image__error"
          >
            {{ errorMsg }}
          </div>
          <template v-if="errorMessages.length">
            <div
              v-for="(message,index) in errorMessages"
              :key="`'error-message-'${index}`"
              class="upload-image__error"
            >
              {{ message }}
            </div>
          </template>
          <ItemButtons
            v-if="!isSkipped || canBeChange"
            :loading="loading"
            :can-be-skipped="canBeSkipped && !loading"
            :skip-button-show="showSkipButton"
            :submit-button-show="showSubmitButton"
            :submit-button-text="submitText"
            :submit-button-color="submitButtonColor"
            :data-test-submit="'image-form-item-btn-' + (canBeChange ? 'reselect': 'select')"
            data-test-skip="image-form-item-btn-skip"
            @skip="skip"
            @submit="onClick"
          />
          <VFileInput
            ref="input"
            v-model="src"
            type="file"
            :accept="$options.ACCEPTED_IMAGE_TYPE"
            class="d-none"
            data-test-label="mage-form-item-file-input"
          />
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
import toBase64 from '@/helpers/toBase64';
import ItemButtons from '@/components/items/ItemButtons.vue';
import { ACCEPTED_IMAGE_TYPE } from './constants';

export default {
  name: 'ImageFormItem',
  components: { ItemButtons },
  ACCEPTED_IMAGE_TYPE,
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([]),
    },
    isSkipped: {
      type: Boolean,
      default: false,
    },
    isItemCompleted: {
      type: Boolean,
      default: false,
    },
    taskCompleted: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dragAndDropCapable: false,
    dragover: false,
    loading: false,
    fileWentToUpload: false,
    fileName: '',
    errorMsg: '',
    src: null,
    image: '',
  }),
  computed: {
    canBeChange() {
      return (!!this.meta?.file?.url || this.image) && !this.taskCompleted;
    },
    submitText() {
      return this.canBeChange ? this.$t('items.add_photo.change_photo') : this.$t('items.add_photo.submit');
    },

    submitButtonColor() {
      return this.canBeChange ? 'tt-secondary' : 'tt-primary';
    },
    showSkipButton() {
      if (this.isItemCompleted || this.isSkipped) {
        return false;
      }

      return !this.fileWentToUpload;
    },
    showSubmitButton() {
      return (!this.isSkipped && !this.isItemCompleted) || this.canBeChange;
    },
  },
  watch: {
    src(file) {
      if (file) {
        this.handleFile(file);
      }
    },
  },
  created() {
    if (this.meta?.file?.url) {
      this.image = this.meta?.file?.url;
      this.fileName = this.meta?.file?.name;
    }
  },
  methods: {
    onClick() {
      this.$refs.input.$refs.input.click();
    },
    skip() {
      this.reset();
      this.$emit('skip');
    },
    reset() {
      this.fileName = '';
      this.src = null;
      this.base64 = '';
      this.image = '';
      this.errorMsg = '';
    },
    async handleDrag(event) {
      if (!this.isSkipped) {
        const { dataTransfer } = event;
        if (dataTransfer.files.length > 0) {
          try {
            await this.handleFile(dataTransfer.files[0]);
          } catch (e) {
            console.error(e);
          }
        }
      }
    },
    async handleFile(file) {
      this.loading = true;
      try {
        if (file.size > 15 * 1024 * 1024) {
          this.errorMsg = this.$tc('validators.file_oversize', 15);
          this.loading = false;
          return;
        }
        if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
          this.errorMsg = this.$t('validators.file_extension_wrong');
          this.loading = false;
          return;
        }
        const onLoadedCallback = async () => {
          this.image = await toBase64(file);
          this.fileName = file.name;
          this.loading = false;
          if (this.errorMessages.length) {
            this.reset();
          }
        };
        const image = await toBase64(file);
        const fileName = file.name;
        const base64 = image.substring(image.indexOf('base64,') + 7);
        this.$emit('submit', {
          payload: {
            content_base64: base64,
            fileId: 0,
            name: fileName,
          },
          callback: onLoadedCallback,
        });
        this.fileWentToUpload = true;
        this.errorMsg = '';
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style>
.upload-image {
  padding: 16px;
  background: #FFFFFF;
  border: 1px dashed #EBECED;
  box-sizing: border-box;
  border-radius: 5px;
  flex-direction: column;
}
.upload-image_compact{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.upload-image .upload-image__wrapper {
  width: 100%;
  height: 230px;
  overflow: hidden;
  background-color: #F5F6F6;
  border: 1px solid #EBECED;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-image__format {
  font-size: 14px;
  line-height: 19px;
}
.upload-image .upload-image__wrapper .background-img{
  width: 100%;
  height: 230px;
}
.upload-image .upload-image__wrapper.upload-image__wrapper_dragover {
  background-color: #EBECED;
}
.upload-image .upload-image__error {
  color: #EF323F;
}
.upload-image__action {
  cursor: pointer;
}
.upload-image .upload-image__wrapper_compact{
  width: 110px;
  height: 110px;
  border-radius: 12px;
  margin-bottom: 0;
  margin-right: 16px;
}
.upload-image .upload-image__wrapper_compact .background-img{
  width: 110px;
  height: 110px;
}
.text-link {
  cursor: pointer;
}
</style>
