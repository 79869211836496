import dayjs from 'dayjs';

// eslint-disable-next-line import/prefer-default-export
export const formatDate = (date, dateFormat = 'LLL') => dayjs(date)
  .format(dateFormat);

function generatePaymentDate({ paymentDay = 0, isPrepayment = false }) {
  if (!paymentDay) {
    return null;
  }
  const currentDate = dayjs();
  /*
   * Если день аванса или зарплаты превышает количество дней в текущем месяце,
   * то в качестве дня расчетной даты месяца  устанавливается последний день месяца
   */
  let paymentDate = dayjs()
    .set('date', (paymentDay > currentDate.endOf('month')
      .date()
      ? dayjs()
        .endOf('month')
        .date()
      : paymentDay));
  /*
     * Если текущая дата уже перешла границы расчетной даты, то переносим расчетную дату на
     * следующий месяц для правильных расчетов
     * */
  if (currentDate.isAfter(paymentDate)) {
    paymentDate = paymentDate.add(1, 'M');
  }
  /*
 * Считаем сколько дней осталось до расчетной даты
 * */
  const leftDays = paymentDate.diff(currentDate, 'day');
  return {
    leftDays,
    isPrepayment,
  };
}
export function calculatePaymentInfo({
  prepaymentInfo,
  paymentInfo,
}) {
  /*
  * Считаем дату расчету для аванса
  * */
  const prepaymentDate = generatePaymentDate({ paymentDay: prepaymentInfo.day, isPrepayment: true });
  /*
  * Считаем дату расчета для зарплаты
  * */
  const paymentDate = generatePaymentDate({ paymentDay: paymentInfo.day, isPrepayment: false });

  /*
  * Сортируем даты по возрастанию
  * */
  const arrayDates = [prepaymentDate, paymentDate]
    .filter((item) => item !== null)
    .sort((a, b) => a.leftDays - b.leftDays);
  if (!arrayDates.length) {
    throw new Error('Dates are not exists');
  }
  /*
  * Возвращаем первый объект расчетной даты
  * */
  return arrayDates[0];
}
